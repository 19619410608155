import { namespace } from "vuex-class";
import { StoreModule } from "@/store/types";
import { GlobalActions, GlobalGetters } from "@/store/modules/global/types";
import { UserActions, UserGetters } from "@/store/modules/user/types";
import { User, UserProfileSchema } from "@/models/user/user";
import { Component, Vue } from "vue-property-decorator";
import { Roles } from "@/models/user/roles";
import AuthService from "@/services/auth_service";
import UserService from "@/services/user_service";
import { AuthError, GenericError } from "@/services/error_service";

@Component
export class Auth extends Vue {
  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetLoggedIn))
  isLoggedIn!: boolean;
  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetRole))
  userRole!: Roles;
  @(namespace(StoreModule.User).Getter(UserGetters.GetUser))
  user!: User;
  @(namespace(StoreModule.User).Getter(UserGetters.GetMsrpMultiplier))
  msrpMultiplier!: number;
  @(namespace(StoreModule.User).Getter(UserGetters.GetFileId))
  fileBeingEditedId!: string;
  @(namespace(StoreModule.Global).Action(GlobalActions.AddLoggedIn))
  setLoggedIn!: (value: boolean) => Promise<void>;
  @(namespace(StoreModule.Global).Action(GlobalActions.AddRole))
  setUserRole!: (value: Roles | null) => Promise<void>;
  @(namespace(StoreModule.User).Action(UserActions.AddUser))
  setUserInfo!: (value: User | null) => Promise<void>;
  @(namespace(StoreModule.User).Action(UserActions.UpdateUserProfile))
  setUserProfileInfo!: (value: UserProfileSchema) => Promise<void>;
  @(namespace(StoreModule.User).Action(UserActions.AddFileId))
  addFileBeingEditedId!: (value: string) => Promise<void>;
  protected authService = new AuthService();
  protected userService = new UserService();

  public async checkAuth(): Promise<void> {
    try {
      await this.authService.checkAuth();
      await this.setLoggedIn(true);
      const user = await this.userService.getUserProfile();
      await this.setUserRole(user.role);
      const sdAppUser = this.filterAppUserData(user);
      await this.setUserInfo(sdAppUser);
    } catch (err) {
      await this.setLoggedIn(false);
      await this.setUserRole(null);
      await this.setUserInfo(null);
    }
  }

  public async logout(): Promise<void> {
    try {
      await this.addFileBeingEditedId("");
      await this.setLoggedIn(false);
      await this.setUserRole(null);
      await this.setUserInfo(null);
      await this.authService.logout();
      this.$router.push("/");
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        GenericError.popup(err.message);
      }
    }
  }

  private filterAppUserData(user: User): User {
    const filteredLoginHistory = user.login_history.filter(
      appObj => appObj.app_url === process.env.VUE_APP_URL
    );
    return {
      ...user,
      login_history: filteredLoginHistory
    };
  }
}
