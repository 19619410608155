



































































import { Component, Watch } from "vue-property-decorator";
import Header from "@/components/designer/header/Header.vue";
import Configurator from "@/components/designer/Configurator.vue";
import Menu from "@/components/designer/header/Menu.vue";
import Footer from "@/components/designer/footer/Footer.vue";
import { mixins } from "vue-class-component";
import { LayoutStore, ShapeStore } from "@/mixins/store";
import { Auth } from "@/mixins/auth";
import { FabricRules } from "@/mixins/business_logic/global/fabric_rules";
import { Roles } from "@/models/user/roles";
import ConfigService from "@/services/config_file_service";
import {
  APIError,
  AuthError,
  NotFoundError,
  PermissionsError,
} from "@/services/error_service";
import { AttributeRules } from "@/mixins/business_logic/global/attribute_rules";
import { UmbrellaModel } from "@/models/products/umbrella";
import AuthService from "@/services/auth_service";
import DesignerIconMenu from "@/components/designer/icon_menu/DesignerIconMenu.vue";
import ContestEnterPopup from "@/components/contest/ContestEnterPopup.vue";
import DisclaimerModal from "@/components/designer/reusable/modal/DisclaimerModal.vue";
import { StockMixin } from "@/mixins/stock";
import { ClearAllPresetDesigns } from "@/mixins/helpers";

@Component({
  components: {
    Configurator,
    Menu,
    Header,
    Footer,
    DesignerIconMenu,
    ContestEnterPopup,
    DisclaimerModal,
  },
})
export default class Designer extends mixins(
  LayoutStore,
  ShapeStore,
  Auth,
  FabricRules,
  AttributeRules,
  StockMixin
) {
  protected Roles = Roles;
  protected editMode = false;
  protected umbrellaName = "";
  protected authService = new AuthService();
  protected showScrollArrow = true; 

  @Watch('$route')
  onRouteChange(): void {
    this.$nextTick(() => {
      this.updateScrollArrowVisibility();

      setTimeout(() => {
        this.updateScrollArrowVisibility();
      }, 500);  // Secondary check after a short delay to allow for content in sidebar to load
    });
  }

  @Watch("fileBeingEditedId", { immediate: true, deep: true })
  onNewSaveFile(newValue: string, oldValue: string): void {
    if (this.fileBeingEditedId && newValue !== oldValue) {
      this.editMode = true;
      this.fetchFileName();
    }
  }

  /** Calls method in attribute_rules mixin */
  @Watch("umbrellaModel", { immediate: true, deep: true })
  protected onModelChange(
    newModel: UmbrellaModel,
    oldModel: UmbrellaModel
  ): void {
    this.checkModelAttributes();
  }

  /** Calls method in fabric_rules mixin */
  // @Watch("ribFabric", {deep: true})
  // protected onRibFabricChange(): void {
  //   this.ribFabricRules();
  // }

  /** Calls method in fabric_rules mixin */
  @Watch("valance", { deep: true })
  protected onValanceChange(): void {
    this.valanceFabricRules();
  }

  /** Calls method in fabric_rules mixin */
  @Watch("canopyFabric", { deep: true })
  protected onCanopyFabricChange(): void {
    this.setWhiteFabric();
    this.canopyFabricRules();
  }

  created() {
    if (this.$route.query.redirect) { //ToDo: FUMBSD-599: current breaking point for new users after logging in, currently requires manual browser refresh
      this.checkAuth();
    }
  }

  mounted() {
    /* 2023-05-22: Start Designer history block: Designer needs history in order to work correctly (for some reason). */
    this.$nextTick(() => {
      // create storage variable & reload page if nonexistent
      if (localStorage.getItem("shadeDesignerHistory") !== "true") {
        localStorage.setItem("shadeDesignerHistory", "true");
        location.reload();
      }
    });
    /* End Designer history block. */

    if (!this.collection || !this.umbrellaModel) {
      this.$router.push("/");
    }
    if (this.$route.query.quickship) {
      const loader = this.$loading.show({ opacity: 1 });
      setTimeout(() => {
        loader.hide();
      }, 3000);
    }
    if (
      this.isLoggedIn &&
      this.fileBeingEditedId &&
      Object.prototype.hasOwnProperty.call(this.$route.query, "edit")
    ) {
      this.setEditingUmbrellaMode();
    } else if (
      Object.prototype.hasOwnProperty.call(this.$route.query, "edit")
    ) {
      this.turnOffEditMode();
    } else {
      this.addFileBeingEditedId("");
      this.editMode = false;
    }
    document.body.classList.add("designerBody");
    if (this.collection.config_file) {
      localStorage.setItem(
        "shadeDesigner-defaultCanopy",
        JSON.stringify(this.collection.config_file[0].canopy) || "N/A"
      );
    }

    if (this.$refs.spinnerContainer) {
      (this.$refs.spinnerContainer as HTMLElement).addEventListener(
        "scroll",
        this.handleScroll
      );

      this.updateScrollArrowVisibility();
      // Additional check after a longer delay to ensure all content is loaded
      setTimeout(() => {
        this.updateScrollArrowVisibility();
      }, 500);
    }
  }

  destroyed(): void {
    document.body.classList.remove("designerBody");
    localStorage.removeItem("shadeDesigner-defaultCanopy");
    if (this.$refs.spinnerContainer) {
      (this.$refs.spinnerContainer as HTMLElement).removeEventListener(
        "scroll",
        this.handleScroll
      );
    }
  }

  protected handleScroll(event: Event): void {
    this.updateScrollArrowVisibility();
  }

  protected isScrollbarVisible(container: HTMLElement): boolean {
    return container.scrollHeight > container.clientHeight;
  }

  protected updateScrollArrowVisibility(): void {
    this.$nextTick(() => {
      const container = this.$refs.spinnerContainer as HTMLElement;

      // Show the arrow if there is a scrollbar and we are not at the bottom
      this.showScrollArrow =
        this.isScrollbarVisible(container) &&
        container.scrollTop + container.clientHeight < container.scrollHeight;
    });
  }

  protected async tiltUmbrella(): Promise<void> {
    await this.tilt();
  }

  protected turnOffEditMode(): void {
    this.$router.replace("/designer");
    this.addFileBeingEditedId("");
    this.editMode = false;
  }

  protected setEditingUmbrellaMode(): void {
    this.editMode = true;
    this.fetchFileName();
  }

  protected async fetchFileName(): Promise<void> {
    const configService = new ConfigService();
    try {
      const res = await configService.getFile(this.fileBeingEditedId);
      this.umbrellaName = res.name ? res.name : "";
    } catch (err) {
      if (err instanceof NotFoundError) {
        NotFoundError.popup(err.message, err.statusCode);
      } else if (err instanceof PermissionsError) {
        PermissionsError.popup(err.message, err.statusCode);
      } else if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        APIError.popup(err.message, err.statusCode);
      }
    }
  }

  protected ready(): void {
    /** From mixin fabric_rules */
    this.initFabricRules();
  }

  protected scrollToBottom(): void {
    const container = this.$refs.spinnerContainer as HTMLElement;
    container.scrollTo({
      top: container.scrollHeight,
      behavior: "smooth",
    });
  }
}
