import { ConfigFile } from "@/models/configurator/config_file";
import { CustomUmbrella } from "@/models/configurator/custom_umbrella";
import { User, UserProfileSchema } from "@/models/user/user";
import axios, { AxiosResponse } from "axios";
import {
  APIError,
  AuthError,
  BadRequest,
  NotFoundError,
  PermissionsError
} from "./error_service";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_NODE_URL;
axios.defaults.headers.apikey = process.env.VUE_APP_API_KEY;

export default class UserService {
  public async getUserProfile(id = "me"): Promise<User> {
    let profile = {} as User;
    try {
      const res = await axios.get("/users/profile/" + id);
      profile = res.data.data;
    } catch (err) {
      if (err.response && err.response.status === 404) {
        throw new NotFoundError(err.response.data.message, err.response.status);
      } else if (err.response && err.response.status === 403) {
        throw new PermissionsError(
          err.response.data.message,
          err.response.status
        );
      } else if (err.response && err.response.status === 401) {
        throw new AuthError(err.response.data.message, err.response.status);
      } else {
        throw new APIError(
          "Something went wrong, please try again.",
          err.response.status
        );
      }
    }
    return profile;
  }

  public async updateUserProfile(
    req: UserProfileSchema,
    id = "me"
  ): Promise<void> {
    try {
      await axios.put("/users/profile/" + id, req);
    } catch (err) {
      if (err.response && err.response.status === 404) {
        throw new NotFoundError(err.response.data.message, err.response.status);
      } else if (err.response && err.response.status === 403) {
        throw new PermissionsError(
          err.response.data.message,
          err.response.status
        );
      } else if (err.response && err.response.status === 401) {
        throw new AuthError(err.response.data.message, err.response.status);
      } else {
        throw new APIError(
          "Something went wrong, please try again.",
          err.response.status
        );
      }
    }
  }

  public async updateNotificationsTimestamp(
    req: User,
    id = "me"
  ): Promise<User> {
    try {
      const body = {
        ...req,
        appUrl: process.env.VUE_APP_URL,
        timestamp: new Date()
      };
      const res = await axios.put("/users/notifications/" + id, body);
      return res.data.data;
    } catch (err) {
      if (err.response && err.response.status === 404) {
        throw new NotFoundError(err.response.data.message, err.response.status);
      } else if (err.response && err.response.status === 403) {
        throw new PermissionsError(
          err.response.data.message,
          err.response.status
        );
      } else if (err.response && err.response.status === 401) {
        throw new AuthError(err.response.data.message, err.response.status);
      } else {
        throw new APIError(
          "Something went wrong, please try again.",
          err.response.status
        );
      }
    }
  }

  public async getSavedConfigs(id = "me"): Promise<ConfigFile[]> {
    let savedFiles: ConfigFile[] = [];
    try {
      const res = await axios.get("/users/files/" + id);
      savedFiles = res.data.data;
    } catch (err) {
      if (err.response && err.response.status === 404) {
        throw new NotFoundError(err.response.data.message, err.response.status);
      } else if (err.response && err.response.status === 403) {
        throw new PermissionsError(
          err.response.data.message,
          err.response.status
        );
      } else if (err.response && err.response.status === 401) {
        throw new AuthError(err.response.data.message, err.response.status);
      } else {
        throw new APIError(
          "Something went wrong, please try again.",
          err.response.status
        );
      }
    }
    return savedFiles;
  }
}
