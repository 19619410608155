var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"fabric-selector-container"},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.closeComparisonModal.apply(null, arguments)}}},[_c('div',{ref:"fabricSelector",attrs:{"id":"fabric-selector","uk-offcanvas":"mode: none; flip: true; bg-close: false;"}},[_c('div',{ref:"spinnerContainerFabric",staticClass:"uk-offcanvas-bar"},[_c('div',{staticClass:"fabric-selector-fixed-header"},[_c('button',{staticClass:"uk-offcanvas-close",attrs:{"type":"button"}},[_c('CloseIcon')],1),_vm._v(" "),_c('div',{staticClass:"currently-viewing"},[_c('p',[_vm._v("\n            Grade"),(_vm.filters.grade.length > 1)?_c('span',[_vm._v("s")]):_vm._e(),_vm._v("\n            "+_vm._s(_vm.filters.grade.length > 1 ? "A/A+"
              : _vm.filters.grade[0] === "a" ? "A Stock"
              : _vm.filters.grade[0] === "ap" ? "A+"
              : _vm.filters.grade[0].toUpperCase())+"\n            "),_c('strong',[_vm._v(_vm._s(_vm.component))])])]),_vm._v(" "),_c('div',{staticClass:"uk-margin-small"},[_c('div',{staticClass:"uk-inline uk-flex uk-flex-bottom uk-flex-around"},[_c('button',{staticClass:"\n                search-icon\n                uk-form-icon uk-form-icon-flip uk-position-relative\n              "},[_c('SearchIcon')],1),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchTerm),expression:"searchTerm"}],staticClass:"uk-input",attrs:{"type":"text","id":"filter-search","placeholder":"Search by code or name"},domProps:{"value":(_vm.searchTerm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchTerm=$event.target.value}}}),_vm._v(" "),(_vm.searchTerm)?_c('button',{attrs:{"id":"fabricSearchClose","type":"button fabric-search-close"}},[_c('CloseIcon',{nativeOn:{"click":function($event){_vm.searchTerm = ''}}})],1):_vm._e()])])]),_vm._v(" "),(_vm.filteredFabrics.length > 0)?_c('ul',{staticClass:"\n    fabric-swatch-selector-wrapper\n    uk-flex uk-flex-left uk-flex-wrap uk-child-width-1-3\n  "},_vm._l((_vm.filteredFabrics),function(fabric,index){return _c('li',{key:fabric.mfr_code + index.toString(),staticClass:"fabric-item"},[_c('Swatch',{class:{
      selected: _vm.selected && _vm.selected.mfr_code === fabric.mfr_code,
    },attrs:{"img":fabric.image,"discontinued":fabric.discontinued,"comparisons":fabric.comparisons,"mfr":fabric.mfr},nativeOn:{"click":function($event){return _vm.toggleComparisonModal(fabric, $event)}},scopedSlots:_vm._u([{key:"mfr_code",fn:function(){return [_vm._v(_vm._s(fabric.mfr_code))]},proxy:true},{key:"default",fn:function(){return [_vm._v(_vm._s(fabric.name))]},proxy:true},{key:"mfr_name",fn:function(){return [_vm._v("by "+_vm._s(fabric.mfr))]},proxy:true},{key:"grade",fn:function(){return [_vm._v(" \n      GRADE\n      "+_vm._s(fabric.grade === "a" ? "A Stock"
        : fabric.grade === "ap" ? "A+"
        : fabric.grade.toUpperCase())+"\n    ")]},proxy:true}],null,true)}),_vm._v(" "),(_vm.comparisonModalOpen && _vm.comparisonFabric && _vm.comparisonFabric.mfr_code === fabric.mfr_code && _vm.getComparisonSwatches(_vm.comparisonFabric.comparisons).length > 0)?_c('div',{staticClass:"comparison-modal",style:({ width: _vm.ulWidth, left: _vm.ulLeft })},[_c('h4',[_vm._v("Fabric Comparisons for "+_vm._s(fabric.name))]),_vm._v(" "),_c('div',{staticClass:"uk-flex uk-flex-center uk-flex-middle uk-flex-wrap"},[_vm._l((_vm.getComparisonSwatches(_vm.comparisonFabric.comparisons)),function(comparison){return _c('Swatch',{key:comparison.mfr_code,class:{
        'selected': _vm.selected && _vm.selected.mfr_code === comparison.mfr_code,
      },attrs:{"img":comparison.image},nativeOn:{"click":function($event){return _vm.select(comparison)}},scopedSlots:_vm._u([{key:"mfr_code",fn:function(){return [_vm._v(_vm._s(comparison.mfr_code))]},proxy:true},{key:"default",fn:function(){return [_vm._v(_vm._s(comparison.name))]},proxy:true},{key:"mfr_name",fn:function(){return [_vm._v("by "+_vm._s(comparison.mfr))]},proxy:true},{key:"grade",fn:function(){return [_vm._v("\n        GRADE\n        "+_vm._s(comparison.grade === "a"
            ? "A Stock"
            : comparison.grade === "ap"
            ? "A+"
            : comparison.grade.toUpperCase())+"\n      ")]},proxy:true}],null,true)})}),_vm._v(" "),_c('FabricComparisonDisclaimer'),_vm._v(" "),_c('button',{staticClass:"modal-close-btn",on:{"click":_vm.closeComparisonModal}},[_vm._v("Close")])],2)]):_vm._e()],1)}),0):_c('ul',[_vm._m(0)]),_vm._v(" "),_vm._m(1)]),_vm._v(" "),_c('FabricFilter',{attrs:{"filters":_vm.filters,"solidsOnly":_vm.solidsOnly,"noStripes":_vm.noStripes,"isSpecialFabricComponent":_vm.isSpecialFabricComponent,"mainFabric":_vm.mainFabric,"component":_vm.component,"isCustomFabricWeight":_vm.isCustomFabricWeight},on:{"setGrade":_vm.setGrade,"setColor":_vm.setColor,"setDesign":_vm.setDesign,"setMfr":_vm.setMfr,"setWeight":_vm.setWeight,"setCustomWeight":_vm.setCustomWeight}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('p',[_vm._v("No results.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"floating-buttons uk-flex"},[_c('div',[_c('button',{staticClass:"button uk-offcanvas-close bottom-save-button",attrs:{"type":"button"}},[_vm._v("\n            SAVE & CLOSE\n          ")])])])}]

export { render, staticRenderFns }