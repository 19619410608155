







































































import { Component } from "vue-property-decorator";
import Header from "@/components/designer/header/Header.vue";
import { User, UserProfileSchema } from "@/models/user/user";
import UserService from "@/services/user_service";
import AuthService from "@/services/auth_service";
import Share from "@/components/share/Share.vue";
import {
  AuthError,
  GenericError,
  PermissionsError,
} from "@/services/error_service";
import { mixins } from "vue-class-component";
import { ConfigCreator } from "@/mixins/configurator";
import { Auth } from "@/mixins/auth";

@Component({
  components: { Header, Share },
})
export default class UserProfile extends mixins(ConfigCreator, Auth) {
  protected userService = new UserService();
  protected authService = new AuthService();
  protected userData: null | User = null;
  protected pwRequested = false;
  protected firstName = "";
  protected lastName = "";
  protected nameRules = [
    (v: string) => !!v || "Name is required",
    (v: string) => /^[\p{L} ,.'-]+$/u.test(v) || "Only letters allowed in name",
  ];
  protected email = "";
  protected emailRules = [
    (v: string) => !!v || "E-mail is required",
    (v: string) => /.+@.+/.test(v) || "E-mail must be valid",
  ];
  protected company = "";
  protected companyRules = [
    (v: string) => !!v || "Company is required",
    (v: string) => /^[\p{L} ,.'-]+$/u.test(v) || "Only letters allowed in company",
  ];
  protected msrpMultiplierValue = 1;
  protected msrpMultiplierRules = [
    (v: number) => !!v || "MSRP Multiplier is required",
  ];

  async created() {
    await this.getUserInfo();
  }

  public async getUserInfo(): Promise<void> {
    const loader = this.$loading.show();
    try {
      this.userData = await this.userService.getUserProfile();
      await this.setUserInfo(this.userData);
      this.firstName = this.user.first_name;
      this.lastName = this.user.last_name;
      this.email = this.user.email;
      this.company = this.user.customer_info?.company || "";
      this.msrpMultiplierValue = this.user.customer_info?.msrp_multiplier || 1;
    } catch (err) {
      if (err instanceof PermissionsError) {
        PermissionsError.popup(err.message, err.statusCode);
      } else if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        GenericError.popup(err.message);
      }
    } finally {
      loader.hide();
    }
  }

  public async requestNewPassword(): Promise<void> {
    window.location.replace(
      `${process.env.VUE_APP_LOGIN_URL}/request-email?request=password`
    );
  }

  protected async submitProfile(): Promise<void> {
    let loader = this.$loading.show();
    const body = {
      first_name: this.firstName,
      last_name: this.lastName,
      email: this.email,
      customer_info: {
        company: this.company,
        msrp_multiplier: this.msrpMultiplierValue
      }
    } as UserProfileSchema;
    try {
      await this.userService.updateUserProfile(body);
      await this.setUserProfileInfo(body);
      loader.hide();
      loader = this.$loading.show(
        {},
        { before: this.$createElement("h1", "Saved!") }
      );
      setTimeout(() => loader.hide(), 750);
    } catch (err) {
      loader.hide();
      if (err instanceof PermissionsError) {
        PermissionsError.popup(err.message, err.statusCode);
      } else if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        GenericError.popup(err.message);
      }
    }
  }
}
