import { Notification } from "@/models/notification";
import axios, { AxiosResponse } from "axios";

axios.defaults.withCredentials = true;
// axios.defaults.headers = "Access-Control-Allow-Origin";

export default class NotificationService {
  public async fetchNotifications(): Promise<Array<Notification>> {
    let notifications: Array<Notification> = [];
    const url =
      process.env.VUE_APP_API_NODE_URL + "/notifications/getNotifications";
    const body = {
      releaseNotesUrl: process.env.VUE_APP_NOTIFICATIONS_URL as string
    };
    try {
      const res: AxiosResponse = await axios.get(url, {
        params: body
      });
      notifications = res.data.data.notifications;
      return notifications;
    } catch (error) {
      console.error("Notifications could not be obtained.");
      return notifications;
    }
    /**
     * Test data commented out below.
     */
    // const notifications: Array<Notification> = [];
    // notifications.push(
    //   {
    //     title: "Control your sales prices!",
    //     description:
    //       "Users can now apply a multiplier against the MSRP values to display the desired sales price!",
    //     mediaUrl: null,
    //     attributes: ["new", "account", "improvement", "fix"],
    //     pageUrl: "https://frankfordumbrellas.com?true",
    //     publishDate: new Date("2024-12-05")
    //   },
    //   {
    //     title: "Control your sales prices, again!",
    //     description:
    //       "AGAIN - Users can now apply a multiplier against the MSRP values to display the desired sales price!",
    //     // mediaUrl: null,
    //     attributes: ["new", "bases", "umbrellas"],
    //     mediaUrl: "https://frankford.b-cdn.net/sd/textures/R-160.jpg",
    //     pageUrl: "https://frankfordumbrellas.com?false",
    //     publishDate: new Date("2024-12-05")
    //   },
    //   {
    //     title: "Control your sales prices!",
    //     description:
    //       "Users can now apply a multiplier against the MSRP values to display the desired sales price!",
    //     mediaUrl: null,
    //     attributes: ["new", "account", "improvement", "fix"],
    //     pageUrl: "https://frankfordumbrellas.com?true",
    //     publishDate: new Date("2024-12-05")
    //   },
    //   {
    //     title: "Control your sales prices, again!",
    //     description:
    //       "AGAIN - Users can now apply a multiplier against the MSRP values to display the desired sales price!",
    //     // mediaUrl: null,
    //     attributes: ["new", "bases", "umbrellas"],
    //     mediaUrl: "https://frankford.b-cdn.net/sd/textures/R-160.jpg",
    //     pageUrl: "https://frankfordumbrellas.com?false",
    //     publishDate: new Date("2024-12-05")
    //   },
    //   {
    //     title: "Control your sales prices!",
    //     description:
    //       "Users can now apply a multiplier against the MSRP values to display the desired sales price!",
    //     mediaUrl: null,
    //     attributes: ["new", "account", "improvement", "fix"],
    //     pageUrl: "https://frankfordumbrellas.com?true",
    //     publishDate: new Date("2024-12-05")
    //   },
    //   {
    //     title: "Control your sales prices, again!",
    //     description:
    //       "AGAIN - Users can now apply a multiplier against the MSRP values to display the desired sales price!",
    //     // mediaUrl: null,
    //     attributes: ["new", "bases", "umbrellas"],
    //     mediaUrl: "https://frankford.b-cdn.net/sd/textures/R-160.jpg",
    //     pageUrl: "https://frankfordumbrellas.com?false",
    //     publishDate: new Date("2024-12-05")
    //   }
    // );
    // return notifications;
  }
}
