
























































































import { Component } from "vue-property-decorator";
import Header from "@/components/designer/header/Header.vue";
import { User } from "@/models/user/user";
import { ConfigFile } from "@/models/configurator/config_file";
import UserService from "@/services/user_service";
import Share from "@/components/share/Share.vue";
import {
  APIError,
  AuthError,
  BadRequest,
  GenericError,
  NotFoundError,
  PermissionsError
} from "@/services/error_service";
import { mixins } from "vue-class-component";
import { ConfigCreator } from "@/mixins/configurator";
import ConfigFileService from "@/services/config_file_service";
import { Auth } from "@/mixins/auth";
import { StockMixin } from "@/mixins/stock";

enum Actions {
  Edit = "edit",
  Share = "share",
  Request = "request",
  Delete = "delete"
}

@Component({
  components: { Header, Share }
})
export default class UserDesigns extends mixins(
  ConfigCreator,
  Auth,
  StockMixin
) {
  protected userService = new UserService();
  protected configFileService = new ConfigFileService();
  protected profile: null | User = null;
  protected files: null | ConfigFile[] = null;
  protected Actions = Actions;
  protected showShareDialog = false;
  protected shareLink = "";
  protected fileEditArray: {
    _id: string;
    isEdit: boolean;
  }[] = [];

  async created() {
    this.addFileBeingEditedId("");
    await this.getUserInfo();
    this.setUpEditTracking();
  }

  protected setUpEditTracking(): void {
    if (this.files && this.files?.length > 0) {
      const newArray = this.files.map(f => {
        return { _id: f._id, isEdit: false };
      });
      this.fileEditArray = [...newArray] as any;
    }
  }

  protected saveAndCloseEditField(id: string): void {
    const index = this.fileEditArray.findIndex(f => f._id === id);
    const file = this.files!.find(f => f._id === id);
    this.saveExistingFile(id, file!, index);
  }

  protected async saveExistingFile(
    id: string,
    file: ConfigFile,
    index: number
  ): Promise<void> {
    try {
      file.stock = this.isStockOnly;
      await this.configFileService.updateFile(file, id);
      this.fileEditArray[index].isEdit = false;
    } catch (err) {
      if (err instanceof BadRequest) {
        BadRequest.popup(err.message, err.statusCode);
      } else if (err instanceof NotFoundError) {
        NotFoundError.popup(err.message, err.statusCode);
      } else if (err instanceof PermissionsError) {
        PermissionsError.popup(err.message, err.statusCode);
      } else if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        APIError.popup(err.message, err.statusCode);
      }
    }
  }

  protected openEditField(id: string): void {
    const index = this.fileEditArray.findIndex(f => f._id === id);
    this.fileEditArray[index].isEdit = true;
  }

  protected isInEditMode(id: string): boolean | undefined {
    return this.fileEditArray.find(f => f._id === id)?.isEdit;
  }

  async saveToStore(action: Actions, file: ConfigFile): Promise<void> {
        try {
    await this.transformConfigFileAndSaveToStore(file);
      switch (action) {
        case Actions.Edit:
          await this.addFileBeingEditedId(file._id!);
          this.$router.push("/designer?edit");
          break;
        case Actions.Request:
          this.$router.push({
            name: "RequestQuote",
            params: {
              img1: file.image!
            }
          });
          break;
      }
    } catch (err) {
        APIError.redirect(
          "There was an error loading the umbrella configuration. The selected umbrella and/or its parts or fabric may no longer be available. If you believe this is an error, please contact Frankford Umbrellas directly for more information at info@frankfordumbrellas.com."
        );
    }
  }

  public async getUserInfo(): Promise<void> {
    const loader = this.$loading.show();
    try {
      if (this.user) {
        this.profile = this.user;
      } else {
        this.profile = await this.userService.getUserProfile();
        await this.setUserInfo(this.profile);
      }
      this.files = await this.userService.getSavedConfigs();
    } catch (err) {
      loader.hide();
      if (err instanceof PermissionsError) {
        PermissionsError.popup(err.message, err.statusCode);
      } else if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        GenericError.popup(err.message);
      }
    }
    loader.hide();
  }

  protected async deleteFile(id: string): Promise<void> {
    try {
      await this.configFileService.deleteFile(id);
      this.files = this.files!.filter(f => f._id !== id);
      this.fileEditArray = this.fileEditArray.filter(f => f._id !== id);
    } catch (err) {
      if (err instanceof PermissionsError) {
        PermissionsError.popup(err.message, err.statusCode);
      } else if (err instanceof NotFoundError) {
        NotFoundError.popup(err.message, err.statusCode);
      } else if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        APIError.popup(err.message, err.statusCode);
      }
    }
  }

  // protected async getShareLink(file: ConfigFile): Promise<void> {
  //   try {
  //     delete file.created_at;
  //     delete file.updated_at;
  //     delete file.creator_id;
  //     const res = await this.configFileService.createShareFile(file);
  //     this.shareLink =
  //       process.env.VUE_APP_SHARE_LINK + "/view?design=" + res.share_id;
  //   } catch (err) {
  //     if (err instanceof BadRequest) {
  //       BadRequest.popup(err.message, err.statusCode);
  //     } else {
  //       APIError.popup(err.message, err.statusCode);
  //     }
  //   }
  // }

  // protected async share(file: ConfigFile): Promise<void> {
  //   const loader = this.$loading.show(
  //     {},
  //     { before: this.$createElement("h1", "Generating share link...") }
  //   );
  //   await this.getShareLink(file);
  //   setTimeout(() => {
  //     loader.hide();
  //     this.showShareDialog = true;
  //   }, 1000);
  // }

  protected closeShareModal(): void {
    this.showShareDialog = false;
  }
}
